import axios from 'axios'
import { createApi } from 'goodpath-common'
import cuid from 'cuid'

export const oldApiClient = axios.create({
  baseURL: `/netlify/api`
})

export const newApiClient = axios.create({
  baseURL: `/api`
})

const getNavigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
  }
}

export const addRequestMetadataMiddleware = async (request) => {
  const requestId = cuid.slug() // Use a short ID for easier searching / copy-pasting. Unique enough.
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const locale = getNavigatorLanguage()

  request.headers = {
    ...request.headers,
    'x-request-id': requestId,
    'x-gp-timeZone': timeZone,
    'x-gp-locale': locale
  }

  return request
}

oldApiClient.interceptors.request.use(addRequestMetadataMiddleware)
newApiClient.interceptors.request.use(addRequestMetadataMiddleware)

const webApi = createApi(oldApiClient, newApiClient)

export const addHeader = (key, value) => {
  oldApiClient.defaults.headers.common[key] = value
  newApiClient.defaults.headers.common[key] = value
}

export default webApi
