module.exports = [{
      plugin: require('../plugins/gatsby-early-initialization/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#131313","theme_color":"#131313","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://81d4dcaafbe7476a9426456d2e99691d@sentry.io/1812305","release":"6793c2a75de6295dba4eceae","environment":"develop"},
    },{
      plugin: require('../node_modules/gatsby-plugin-why-did-you-render/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
