import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  createContext
} from 'react'
import { Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import createDecorator from 'final-form-focus'
import api from '../../api'
import registerValidator, {
  employerRegisterValidator,
  employerRegisterValidatorWithDivisions,
  employerRegisterValidatorWithDivisionsAndEnrollmentType
} from '../../validation/registerValidator'
import Button from '../common/Button'
import useUtmCookie from '../../utils/useUtmCookie'
import useLocalStorage from '../../utils/useLocalStorage'
import { useUserContext } from '../../contexts/User/userContext'
import {
  PushToDatalayer,
  trackUserAction
} from '../../utils/analytics-tracking'
import { NoIndex } from '../NoIndex'
import { useGoodpatherCheck } from '../../utils/customHooks'
import { parse } from 'query-string'
import { useEmployerContext } from '../../contexts/Employer/employerProvider'
import { currentActiveQuizKey } from '../../constants/localStorage'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocalizedContent } from '../../utils/localization/useLocalizedContent'
import useSamlCookie from '../../utils/useSamlCookie'

const focusOnErrors = createDecorator()
export const Context = createContext({})

export const EligibilityStatuses = {
  notSet: 'notSet',
  loading: 'loading',
  success: 'success',
  fail: 'fail'
}

const RegisterForm = ({
  children,
  requireEligible,
  onLoading,
  location,
  isExcludedFromEligibilityCheck,
  showDivisions,
  enrollmentTypeSelector,
  onSelectCondition = () => { }
}) => {
  const data = useStaticQuery(query)
  const { translations } = useLocalizedContent(data)

  const [currentActiveQuiz, setCurrentActiveQuiz] = useLocalStorage(
    currentActiveQuizKey
  )

  const samlCookie = useSamlCookie()

  const { slug: employerSlug } = useEmployerContext()
  const [loading, setLoading] = useState(false)
  const [condition, setCondition] = useState('')
  const { user, login, updateUser } = useUserContext()
  const [initialValues, setInitialValues] = useState({
    email: user.email || samlCookie?.attributes?.homeEmail?.[0],
    employeeId: user.employeeId,
    workEmail: user.workEmail || samlCookie?.attributes?.workEmail?.[0],
    firstName: user.firstName || samlCookie?.attributes?.firstName?.[0],
    lastName: user.lastName || samlCookie?.attributes?.lastName?.[0],
    condition: currentActiveQuiz,
    enrollmentType: enrollmentTypeSelector ? 'employee' : null
  })
  const [workEmail, setWorkEmail] = useState(null)
  const [employeeId, setEmployeeId] = useState(null)
  const [eligibilityStatus, setEligibilityStatus] = useState(
    EligibilityStatuses.notSet
  )

  const utmCookies = useUtmCookie()
  useGoodpatherCheck(user.email)

  useEffect(() => {
    const conditionParam = (parse(location?.search) || {}).condition
    conditionParam && setCurrentActiveQuiz(conditionParam)
  }, [location?.search, setCurrentActiveQuiz])

  const setLoadingHandler = useCallback(
    (value) => {
      setLoading(value)
      onLoading(value)
    },
    [onLoading]
  )

  useEffect(() => {
    setCondition(currentActiveQuiz)
    onSelectCondition(currentActiveQuiz)
  }, [currentActiveQuiz, onSelectCondition])

  useEffect(() => {
    if (condition === initialValues.condition) return
    setInitialValues({ ...initialValues, condition: condition })
  }, [initialValues, condition])

  useEffect(() => {
    if (user?.hasAuth && user?.isAuth) setLoadingHandler(false)
  }, [setLoadingHandler, user])

  const userAcqParams = useCallback(() => {
    const referrer = utmCookies.ft_referrer
    const utm_source = utmCookies.ft_utm_source
    const utm_medium = utmCookies.ft_utm_medium
    const utm_campaign = utmCookies.ft_utm_campaign
    const utm_content = utmCookies.ft_utm_content
    const utm_term = utmCookies.ft_utm_term

    return {
      ...(referrer && { referrer }),
      ...(utm_source && { utm_source }),
      ...(utm_medium && { utm_medium }),
      ...(utm_campaign && { utm_campaign }),
      ...(utm_content && { utm_content }),
      ...(utm_term && { utm_term })
    }
  }, [
    utmCookies.ft_referrer,
    utmCookies.ft_utm_campaign,
    utmCookies.ft_utm_content,
    utmCookies.ft_utm_medium,
    utmCookies.ft_utm_source,
    utmCookies.ft_utm_term
  ])

  const validator = useMemo(() => {
    if (requireEligible || workEmail) {
      if (showDivisions && enrollmentTypeSelector) {
        return employerRegisterValidatorWithDivisionsAndEnrollmentType(
          translations
        )
      } else if (showDivisions) {
        return employerRegisterValidatorWithDivisions(translations)
      }
      return employerRegisterValidator(translations)
    } else {
      return registerValidator(translations)
    }
  }, [
    enrollmentTypeSelector,
    requireEligible,
    showDivisions,
    workEmail,
    translations
  ])

  /**
   * Moved on submit validate as we want to track the user action
   * only onSubmit. Having validator on form element will prevent submit action,
   * so having validator onSubmit method would not track user events if on-type validation fails.
   */
  const validateValues = useCallback(
    (values) => {
      const { password: junkVar, ...safeProps } = values
      const trackingProps = {
        ...safeProps,
        employer: employerSlug
      }
      trackUserAction('Signup Attempt', trackingProps)

      const error = validator(values)

      if (Object.keys(error || {}).length > 0) {
        trackUserAction('Signup Validation Error', trackingProps)
        return error
      }
    },
    [employerSlug, validator]
  )

  const onSubmit = useCallback(
    async (props) => {
      const { password: junkVar, ...safeProps } = props
      const trackingProps = {
        ...safeProps,
        employer: employerSlug
      }

      console.log('requireEligible', requireEligible)
      setLoadingHandler(true)
      console.log(`RegisterForm onSubmit props`, safeProps)
      const {
        condition,
        firstName,
        lastName,
        password,
        enrollmentType,
        division
      } = props
      const email = props.email.toLowerCase()
      const workEmail = props.workEmail ? props.workEmail.toLowerCase() : null
      const employeeId = props.employeeId
        ? props.employeeId.toLowerCase()
        : null

      try {
        // Set the selected condition for intake assessment in case they abandon
        // the assessment before answering the first question
        if (condition !== currentActiveQuiz) setCurrentActiveQuiz(condition)

        const isEligible =
          isExcludedFromEligibilityCheck ||
          (eligibilityStatus === EligibilityStatuses.success && employerSlug)

        console.log(
          `RegisterForm onSubmit isEligible=${isEligible} employer=${employerSlug} eligibilityStatus=${eligibilityStatus}`
        )

        if (requireEligible && !isEligible) {
          PushToDatalayer({
            event: 'gtm.employee-verified-failed'
          })
          if (workEmail) {
            console.log(
              `RegisterForm onSubmit employer eligibility required and work email ${workEmail} is not eligible`
            )
          } else if (employeeId) {
            console.log(
              `RegisterForm onSubmit employer eligibility required and employeeID ${employeeId} is not eligible`
            )
          }

          setLoadingHandler(false)

          return {
            [FORM_ERROR]:
              translations[translationKeys.eligibilityError] ||
              'Eligibility check failed. Please try again or contact your employer.'
          }
        }

        try {
          const registeredUserPayload = {
            condition,
            firstName,
            lastName,
            email,
            password,
            ...(division && { division }),
            ...(employerSlug && {
              employerSlug,
              workEmail,
              employeeId,
              enrollmentType
            }),
            ...userAcqParams()
          }
          const resp = await api.user.register(registeredUserPayload)

          if (/200|201/.test(`${resp.status}`)) {
            trackUserAction('Signup Success', trackingProps)
            const user = resp.data
            const { workEmail, employeeId } = user
            updateUser({ workEmail, employeeId })
            console.log(
              `RegisterForm success, login and navigate to /quiz/${condition}`
            )
            await login(email, password, `/quiz/${condition}`)

            // This legacy event name still used for GA goals and ad conversion tracking
            PushToDatalayer({
              event: `gtm.email-subscribe`
            })

            return { [FORM_ERROR]: undefined }
          } else {
            // noinspection ExceptionCaughtLocallyJS
            trackUserAction('Signup Failed', trackingProps)
            throw new Error(
              `Unexpected response code: ${resp.status}.`,
              resp.status
            )
          }
        } catch (error) {
          console.log(error)
          setLoadingHandler(false)
          trackUserAction('Signup Failed', trackingProps)
          return {
            [FORM_ERROR]:
              translations[translationKeys.accountCreationError] ||
              'Could not create account with this email. Please log in if you already have an account.'
          }
        }
      } catch (error) {
        console.log(error)
        setLoadingHandler(false)
        PushToDatalayer({
          event: 'gtm.email-registration-failed'
        })
        trackUserAction('Signup Failed', trackingProps)

        return {
          [FORM_ERROR]:
            translations[translationKeys.registerError] ||
            'An error occurred while registering. Please contact support at support@goodpath.com.'
        }
      }
    },
    [
      currentActiveQuiz,
      eligibilityStatus,
      employerSlug,
      isExcludedFromEligibilityCheck,
      login,
      requireEligible,
      setCurrentActiveQuiz,
      setLoadingHandler,
      updateUser,
      userAcqParams,
      translations
    ]
  )

  return (
    <Context.Provider
      value={{
        eligibilityStatus,
        setEligibilityStatus,
        isExcludedFromEligibilityCheck
      }}
    >
      <NoIndex />
      <Form
        onSubmit={onSubmit}
        decorators={[focusOnErrors]}
        initialValues={initialValues}
        keepDirtyOnReinitialize={true}
        validate={validator}
        render={({ submitError, handleSubmit, values, errors, touched }) => {
          if (values.workEmail && values.workEmail !== workEmail) {
            setWorkEmail(values.workEmail)
          }

          if (values.employeeId || values.employeeId !== employeeId) {
            setEmployeeId(values.employeeId)
          }

          const submitHandler = (event) => {
            validateValues(values)
            handleSubmit(event)
          }

          return (
            <form className="w-full" onSubmit={submitHandler}>
              {children}
              <div className="pt-6">
                <Button
                  id="submit-button"
                  type="submit"
                  isBlock={true}
                  isLoading={loading}
                >
                  {translations[translationKeys.startEnrollment] ||
                    'Start your enrollment'}
                </Button>
              </div>
              {submitError && (
                <div className="block mt-2 error text-ui-error bold">
                  {submitError.message ? submitError.message : submitError}
                </div>
              )}
            </form>
          )
        }}
      />
    </Context.Provider>
  )
}

const translationKeys = {
  startEnrollment: 'startEnrollment',
  registerError: 'registerError',
  accountCreationError: 'accountCreationError',
  eligibilityError: 'eligibilityError',
  waitingListError: 'waitingListError'
}

const query = graphql`
  query RegisterFormQuery {
    allContentfulContentGroup(filter: { readableId: { eq: "register-form" } }) {
      nodes {
        ...ContentGroupFragment
      }
    }
  }
`

export default RegisterForm
